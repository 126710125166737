import React, {Component} from 'react';
import { Carousel } from 'react-bootstrap';
import Credera from './images/credera_red.jpg';
import USAA from './images/usaa_blue.jpg';
import texasam from './images/am.jpg';
import rrisd from './images/rrisd.jpg';
import './css/Carousel.css'
import './css/General.css'
class WorkCarousel extends Component {
  render() {
    return (
      <Carousel interval="7000">
        <Carousel.Item>
          <img 
            className="centerComponent credera"
            src={Credera}
            alt="Credera"
          />
          <Carousel.Caption>
            <h1>Credera</h1>
            <h3>Technology Consulting Intern</h3>
            <ul >
              <li>Developed front-end code in React-Redux and back-end code in C# for application running a multi-million-dollar factoring company</li>
              <li>Utilized Scrum and Visual Studio Team Services for Agile development to prioritize client needs</li>
              <li>Demonstrated completed application features to client and utilized feedback for continued improvement</li>
              <li>Attended development luncheons regarding leadership, innovation, and business etiquette</li>
            </ul>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img 
            className="centerComponent usaa"
            src={USAA}
            alt="USAA"
          />
          <Carousel.Caption>
            <h1>USAA</h1>
            <h3>Software Developer &amp; Integrator</h3>
            <ul>
              <li>Developed queries used to analyze USAA’s Splunk data usage to decrease any inefficient practices</li>
              <li>Researched and created a best procedure guide to optimize Splunk usage</li>
              <li>Presented Splunk guide and its potential savings to technical executives </li>
            </ul>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img 
            className="centerComponent texasam"
            src={texasam}
            alt="Texas AM"
          />
          <Carousel.Caption>
            <h1 className="long">Texas A&amp;M Computer Science and Engineering</h1>
            <h3>Peer Teacher Program Coordinator</h3>
            <ul >
              <li>Organize and manage schedules and communication for all 50 peer teachers</li>
              <li>Manage semesterly orientation for all peer teachers</li>
              <li>Analyze end-of-semester survey on Peer Teacher Program distributed to Computer Science students and report findings to the CSE department heads</li>
            </ul>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img 
            className="centerComponent texasam"
            src={texasam}
            alt="Texas AM"
          />
          <Carousel.Caption>
            <h1 className="long">Texas A&amp;M Computer Science and Engineering</h1>
            <h3>Peer Teacher</h3>
            <ul >
              <li>Teach C++ to students in introductory computer science course 4 times a week</li>
              <li>Hold two office hours a week to answer students’ questions on the subject matter and clear up confusion on their homework</li>
            </ul>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img 
            className="centerComponent rrisd"
            src={rrisd}
            alt="RRISD"
          />
          <Carousel.Caption>
            <h1 >Round Rock ISD</h1>
            <h3>Construction Dept. Intern</h3>
            <ul >
              <li>Managed and coordinated arrival of all furniture for newly constructed elementary school</li>
              <li>Utilized Microsoft Excel to organize information of all prices and counts for the furniture</li>
              <li>Produce usable school footprints using AutoCad for all 52 schools in Round Rock ISD</li>
            </ul>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}
// .carousel-caption
export default WorkCarousel;
import React, {Component} from 'react';
import {Table, Container, Row, Col} from 'react-bootstrap';
class SkillsCoursesTable extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col sm={9} lg={6}>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Skills &amp; Expertise</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>React</td>
                </tr>
                <tr>
                  <td>Redux</td>
                </tr>
                <tr>
                  <td>C++</td>
                </tr>
                <tr>
                  <td>Python</td>
                </tr>
                <tr>
                  <td>C</td>
                </tr>
                <tr>
                  <td>C#</td>
                </tr>
                <tr>
                  <td>Agile Framework</td>
                </tr>
                <tr>
                  <td>Splunk</td>
                </tr>
                <tr>
                  <td>ServiceNow</td>
                </tr>
                
                
              </tbody>
            </Table>
          </Col>
          <Col sm={9} lg={6}> 
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Related Coursework</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Distributed Objects Programming</td>
                </tr>
                <tr>
                  <td>Database Systems</td>
                </tr>
                <tr>
                  <td>Software Security</td>
                </tr>
                <tr>
                  <td>Information Storage &amp; Retrieval</td>
                </tr>
                <tr>
                  <td>Computer System Design</td>
                </tr>
                <tr>
                  <td>Programming Studio</td>
                </tr>
                <tr>
                  <td>Design &amp; Analysis of Algorithms</td>
                </tr>
                <tr>
                  <td>Computer Systems</td>
                </tr>
                <tr>
                  <td>Computer Architecture and Design</td>
                </tr>
                <tr>
                  <td>Data Structures &amp; Algorithms</td>
                </tr>
                <tr>
                  <td>Digital Integrated Circuit Design</td>
                </tr>
                <tr>
                  <td>Electric Circuit Theory</td>
                </tr>
                <tr>
                  <td>Signals &amp; Systems</td>
                </tr>
                <tr>
                  <td>Digital System Design</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      
    );
  }
}

export default SkillsCoursesTable;
import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import "./css/About.css";
class About extends Component {
  render() {
    return (
      <h1> AB </h1>
    )
  }
}

export default About;
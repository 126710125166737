import React, {Component} from 'react';
import {Container, Row, Col, Card, CardDeck, CardImg, Button} from 'react-bootstrap';
import "./css/General.css";
import "./css/Resume.css";
import coding from "./images/coding.jpg";
import coffee from "./images/coffee_card.jpg";
import typewriter from "./images/typewriter.jpg";
import circuit from "./images/circuit.jpg"; 
import ResumeModal from "./ResumeModal";

class Resume extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      modalShow: false,
      title: ""
    };
  }
  render() {
    let modalClose = () => this.setState({modalShow: false});
    return (
      <div>
      <section className="test">
      <Container fluid responsive>
        <Row>
          <Col>
            <h1 className="center heading interactive">Interactive</h1> 
            <h1 className="center heading resume">Resume</h1> 
          </Col>
        </Row> 
        <Row fluid>
          <Col xs={9} md={12}  className="centerComponent">
          <CardDeck>
            <Card>
              <CardImg src={coding} alt="Coding background" fluid/>
              <Card.ImgOverlay>
                <Card.Title> 
                  <Button 
                    onClick={() => this.setState({
                      modalShow: true, title: "work"
                    })}>
                    Work Experience
                  </Button>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
            <Card>
              <CardImg src={coffee} alt="Coffee background" fluid/>
              <Card.ImgOverlay>
                <Card.Title>
                  <Button 
                    onClick={() => this.setState({
                      modalShow: true,
                      title: "leadership"
                    })}>
                    Leadership Roles
                  </Button>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
            <Card>
              <CardImg src={typewriter} alt="Typewriter background" fluid circle/>
              <Card.ImgOverlay>
                <Card.Title> 
                  <Button 
                    onClick={() => this.setState({
                      modalShow: true,
                      title: "awards"
                    })}>
                    Honors &amp; Awards
                  </Button>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
            <Card>
              <CardImg src={circuit} alt="Circuit background" fluid/>
              <Card.ImgOverlay>
                <Card.Title>
                  <Button 
                    onClick={() => this.setState({
                      modalShow: true,
                      title: "skills"
                    })}>
                    Skills &amp; Courses
                  </Button>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </CardDeck>
          </Col>
        </Row> 
      </Container>
      </section>
      <ResumeModal 
        show={this.state.modalShow} 
        onHide={modalClose} 
        title={this.state.title} 
      />
      </div>
    )
  }
}

export default Resume;
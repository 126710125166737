import React, { Component } from 'react';
import logo from './logo.svg';
import Navigation from './Navigation';
import {Switch, Route} from 'react-router-dom';
import Home from './Home';
import About from './About';
import Resume from './Resume';

class App extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <Route exact path="/" component={Home}/>
        <Route  path="/about" component={About}/>
        <Route  path="/resume" component={Resume}/>

      </div>
    );
  }
}

export default App;

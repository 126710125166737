import React, {Component} from 'react';
import { Modal, Button } from 'react-bootstrap';
import WorkCarousel from './WorkCarousel';
import AwardsTable from './AwardsTable';
import SkillsCoursesTable from './SkillsCoursesTable';
class ResumeModal extends Component {
  render() {
    let modelType;
    let title;
    if(this.props.title == "work") {
      modelType = <WorkCarousel />;
      title = "Work Experience";
    }
    else if(this.props.title == "awards") {
      modelType = <AwardsTable />;
      title = "Honors & Awards";
    }
    else if(this.props.title == "skills") {
      title = "Skills & Courses";
      modelType = <SkillsCoursesTable />;
    }
    else {
      modelType = <h1>Amanda</h1>;
    }
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="containted-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modelType}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ResumeModal;
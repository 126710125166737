import React, {Component} from 'react';
import Image from 'react-bootstrap/Image';
import {Container, Row, Col} from 'react-bootstrap';
import AmandaPicutre from './images/AmandaBsaibes_square.jpg';
import laptop from './images/laptop_small.png';
import consultant from './images/consulting_small.png';
import gradcap from './images/gradcap_small.png';
import coffee from './images/coffee_small.png';
import "./css/Home.css";
import "./css/General.css";

class Home extends Component {
  render() {
    return (
      <Container fluid responsive>
        <Row className="homeMainRow">
          <Col xs={12} md={6}>
            <div className="center homePicture centerComponent">
            <Image src={AmandaPicutre} roundedCircle fluid responsive center-block />
            </div>
          </Col>
          <Col xs={12} md={6} fluid>
            <Container responsive fluid>
              <Row>
                <Col> <h1 className="amanda center heading ">Amanda Bsaibes</h1></Col>
              </Row>
              <Row>
                <Col> 
                  <Image src={laptop} fluid responsive inline className="icon"/>
                  <h2 className="center aattributes">Computer Engineer</h2> 
                </Col>
              </Row>
              <Row>
                <Col>
                  <Image src={consultant} fluid responsive inline className="icon"/>
                  <h2 className="center aattributes">Technology Consultant</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Image src={gradcap} fluid responsive inline className="icon"/>
                  <h2 className="center aattributes">Texas A&amp;M Graduate</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Image src={coffee} fluid responsive inline className="icon"/>
                  <h2 className="center aattributes">Coffee Enthusiast</h2>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Home;
import React, {Component} from 'react';
import { Table } from 'react-bootstrap';
import './css/AwardsTable.css';

class AwardsTable extends Component {
  render() {
    return (
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Award Name</th>
            <th>School Year(s) Received</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Computer Science Peer Teacher Excellence Award</td>
            <td>2017-2018</td>
          </tr>
          <tr>
            <td>Society of Women Engineers Honeywell Award</td>
            <td>2018-2019</td>
          </tr>
          <tr>
            <td>Matthew R. Kennedy Presidential Endowed Award</td>
            <td>2015-2019</td>
          </tr>
          <tr>
            <td>Sue and Mack Adwams Endowed Opportunity Award</td>
            <td>2015-2019</td>
          </tr>
          <tr>
            <td>Texas A&amp;M University Academic Excellence</td>
            <td>2017-2018</td>
          </tr>
          <tr>
            <td>Dwight Look College of Engineering Student Award</td>
            <td>2016-2017</td>
          </tr>
          <tr>
            <td>Jackie Price Dunn '02 Endowed Memorial Award</td>
            <td>2017-2018</td>
          </tr>
          <tr>
            <td>Industrial Affiliate Program with Chevron</td>
            <td>2018-2019</td>
          </tr>
          <tr>
            <td>Industrial Affiliate Program with General Motors</td>
            <td>2017-2018</td>
          </tr>
          <tr>
            <td>Industrial Affiliate Program Susan M. Arsevan Endowed Award</td>
            <td>2016-2017</td>
          </tr>
          <tr>
            <td>National Apostolate of Maronites Kassouf Award</td>
            <td>2015-2016</td>
          </tr>
          <tr>
            <td>Austin Contractors &amp; Engineers Association Award</td>
            <td>2015-2016</td>
          </tr>

        </tbody>
      </Table>
    );
  }
}

export default AwardsTable;